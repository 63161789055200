// // home页面的所有数据
import instance from '@/utils/request.js'
import store from '@/store/index.js'
// 设计师列表分页
export const getDesignList = ({
	current,
	size,
	showRoomNo,
	ranks,
	designStyle,
}) => {
    const params = {
		current,
        size,
        showRoomNo,
        ranks,
        designStyle,
        state: 1,
		areaCode: store.state.curCityInfo.value,
        orderBy:"sort asc"
	}
    if (store.state.curCityInfo.value == '00') {
        delete params.areaCode
        params.isHeadOffice = 1
    }
	return instance({
		url: '/portal/designer/list',
		params,
	})
}
//根据id查看设计师详情
export const getDesDetail = (id) => {
	return instance({
		url: '/portal/designer/detail/' + id,
	})
}
